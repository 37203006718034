<template>
  <div id="statistics_page_wrapper">
    <div class="bb-mobile-header">
      <div class="bb-mobile-header-left">
        <button class="mobile-sidebar-btn" @click="openMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
        <button
          class="mobile-period-filter-toggle-btn"
          @click="openMobilePeriodFilter()"
        >
          <span v-if="period.day">{{
            period.day + " " + monthName + " "
          }}</span>
          <span v-if="!period.day && period.month">{{ monthName + " " }}</span>
          <span v-if="!period.day && !period.month">{{ period.year }}</span>
          <small v-if="period.day || period.month">{{ period.year }}</small>
          <i class="la la-angle-down"></i>
        </button>
      </div>
      <div class="bb-mobile-header-right">
        <button class="ml-7" @click="openMobilePlacesFilter()">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0642 0H3.93577C1.76559 0 0 1.76559 0 3.93577V18.0643C0 20.2344 1.76559 22 3.93577 22H18.0643C20.2344 22 22 20.2344 22 18.0642V3.93577C22 1.76559 20.2344 0 18.0642 0ZM20.7109 18.0642C20.7109 19.5236 19.5236 20.7109 18.0642 20.7109H3.93577C2.47638 20.7109 1.28906 19.5236 1.28906 18.0642V3.93577C1.28906 2.47638 2.47638 1.28906 3.93577 1.28906H18.0643C19.5236 1.28906 20.7109 2.47638 20.7109 3.93577V18.0642Z"
              fill="#253342"
            />
            <path
              d="M18.5942 4.83262H8.77756C8.50767 4.03104 7.74949 3.4519 6.85806 3.4519C5.96663 3.4519 5.20845 4.03104 4.93856 4.83262H3.40625C3.0503 4.83262 2.76172 5.1212 2.76172 5.47715C2.76172 5.8331 3.0503 6.12168 3.40625 6.12168H4.9386C5.20849 6.92326 5.96667 7.5024 6.8581 7.5024C7.74953 7.5024 8.50772 6.92326 8.7776 6.12168H18.5943C18.9502 6.12168 19.2388 5.8331 19.2388 5.47715C19.2388 5.1212 18.9502 4.83262 18.5942 4.83262ZM6.85806 6.21333C6.45213 6.21333 6.12188 5.88308 6.12188 5.47715C6.12188 5.07122 6.45213 4.74097 6.85806 4.74097C7.26399 4.74097 7.59424 5.07122 7.59424 5.47715C7.59424 5.88308 7.26399 6.21333 6.85806 6.21333Z"
              fill="#253342"
            />
            <path
              d="M18.5942 10.3556H17.0619C16.792 9.55399 16.0338 8.97485 15.1424 8.97485C14.251 8.97485 13.4928 9.55399 13.2229 10.3556H3.40625C3.0503 10.3556 2.76172 10.6441 2.76172 11.0001C2.76172 11.3561 3.0503 11.6446 3.40625 11.6446H13.2229C13.4928 12.4462 14.251 13.0253 15.1424 13.0253C16.0338 13.0253 16.792 12.4462 17.0619 11.6446H18.5943C18.9502 11.6446 19.2388 11.3561 19.2388 11.0001C19.2388 10.6441 18.9502 10.3556 18.5942 10.3556ZM15.1424 11.7363C14.7365 11.7363 14.4063 11.406 14.4063 11.0001C14.4063 10.5942 14.7365 10.2639 15.1424 10.2639C15.5484 10.2639 15.8786 10.5942 15.8786 11.0001C15.8786 11.406 15.5484 11.7363 15.1424 11.7363Z"
              fill="#253342"
            />
            <path
              d="M18.5942 15.8784H11.539C11.2691 15.0768 10.511 14.4977 9.61953 14.4977C8.7281 14.4977 7.96992 15.0768 7.70003 15.8784H3.40625C3.0503 15.8784 2.76172 16.167 2.76172 16.5229C2.76172 16.8789 3.0503 17.1675 3.40625 17.1675H7.70003C7.96992 17.969 8.7281 18.5482 9.61953 18.5482C10.511 18.5482 11.2691 17.969 11.539 17.1675H18.5943C18.9502 17.1675 19.2388 16.8789 19.2388 16.5229C19.2388 16.167 18.9502 15.8784 18.5942 15.8784ZM9.61953 17.2592C9.21361 17.2592 8.88335 16.9289 8.88335 16.523C8.88335 16.117 9.21361 15.7868 9.61953 15.7868C10.0255 15.7868 10.3557 16.117 10.3557 16.5229C10.3557 16.9289 10.0255 17.2592 9.61953 17.2592Z"
              fill="#253342"
            />
          </svg>
        </button>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center mb-5 filters-container"
    >
      <PlaceFilter @placeChanged="placeChanged($event)" />
      <PeriodFilter @periodChanged="periodChanged($event)" />
    </div>
    <div
      class="d-flex justify-content-between align-items-stretch mb-5 total-cards-container"
    >
      <div class="bb-card total-statistic-card">
        <span>{{ $t("statistics.totalSalesLabel") }}</span>
        <div class="d-flex justify-content-between align-items-baseline">
          <span class="total-statistic-count"
            >{{ currentStatistic.totalSales
            }}<span class="total-statistic-currency">sek</span></span
          >
          <div class="total-statistic-precentage success">
            <span>+68%</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="precentage-arrow feather feather-arrow-up"
            >
              <line x1="12" y1="19" x2="12" y2="5"></line>
              <polyline points="5 12 12 5 19 12"></polyline>
            </svg>
          </div>
        </div>
      </div>
      <div class="bb-card total-statistic-card">
        <span>{{ $t("statistics.totalAppointmentsLabel") }}</span>
        <div class="d-flex justify-content-between align-items-baseline">
          <span class="total-statistic-count">{{
            currentStatistic.totalAppointments
          }}</span>
          <div class="total-statistic-precentage success">
            <span>+26%</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="precentage-arrow feather feather-arrow-up"
            >
              <line x1="12" y1="19" x2="12" y2="5"></line>
              <polyline points="5 12 12 5 19 12"></polyline>
            </svg>
          </div>
        </div>
      </div>
      <div class="bb-card total-statistic-card">
        <span>{{ $t("statistics.completedAppointmentsLabel") }}</span>
        <div class="d-flex justify-content-between align-items-baseline">
          <span class="total-statistic-count">{{
            currentStatistic.completedAppointments
          }}</span>
          <div class="total-statistic-precentage success">
            <span>+26%</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="precentage-arrow feather feather-arrow-up"
            >
              <line x1="12" y1="19" x2="12" y2="5"></line>
              <polyline points="5 12 12 5 19 12"></polyline>
            </svg>
          </div>
        </div>
      </div>
      <div class="bb-card total-statistic-card">
        <span>{{ $t("statistics.canceledAppointmentsLabel") }}</span>
        <div class="d-flex justify-content-between align-items-baseline">
          <span class="total-statistic-count">{{
            currentStatistic.canceledAppointments
          }}</span>
          <div class="total-statistic-precentage danger">
            <span>+26%</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="precentage-arrow feather feather-arrow-up"
            >
              <line x1="12" y1="19" x2="12" y2="5"></line>
              <polyline points="5 12 12 5 19 12"></polyline>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between sales-container">
      <div class="bb-card statistic-card mr-5" style="flex: 1.5;">
        <h4>{{ $t("statistics.staffSalesLabel") }}</h4>
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <StaffChart
            v-show="currentStatistic && currentStatistic.staffSales"
            ref="staffChart"
            :chartData="staffChartData"
            class="staff-chart-container"
          ></StaffChart>
          <p
            class="text-main"
            v-show="!currentStatistic || !currentStatistic.staffSales"
          >
            {{ $t("statistics.noDataLabel") }}
          </p>
          <div class="staff-statistic-list">
            <div
              class="d-flex justify-content-between align-items-center mt-2 mb-2"
              v-for="(staffMember, index) in currentStatistic.staffSales"
              :key="index"
            >
              <div class="d-flex align-items-center">
                <div
                  class="bb-badge badge-xs badge-rounded"
                  :class="staffMember.color"
                ></div>
                <span class="staff-statistic-name">{{
                  staffMember.fullName
                }}</span>
              </div>
              <span class="staff-statistic-precentage"
                >({{ staffMember.precentage }}%)</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bb-card statistic-card" style="flex: 2;">
        <h4>{{ $t("statistics.topServicesCard.title") }}</h4>
        <p v-show="!currentStatistic || !currentStatistic.topServices">
          {{ $t("statistics.noDataLabel") }}
        </p>
        <div class="d-flex justify-content-between align-items-center mb-3">
          <span
            class="statistic-card-label"
            v-show="currentStatistic && currentStatistic.topServices"
            >{{ $t("statistics.topServicesCard.serviceNameLabel") }}</span
          >
          <span
            class="statistic-card-label"
            v-show="currentStatistic && currentStatistic.topServices"
            >{{ $t("statistics.topServicesCard.totalSalesLabel") }}</span
          >
        </div>
        <div
          class="d-flex justify-content-between align-items-center statistic-service-list-item"
          v-for="(service, index) in currentStatistic.topServices"
          :key="index"
        >
          <span>{{ service.service_name }}</span>
          <span>{{ service.total_sales }}</span>
        </div>
      </div>
    </div>
    <div class="bb-card statistic-card total-sales-chart-card mt-5">
      <h4>{{ $t("statistics.totalSalesLabel") }}</h4>
      <div class="bb-line-chart-legend">
        <div class="d-flex align-items-center">
          <div
            class="bb-badge badge-xs badge-rounded"
            style="background: #5860AB;"
          ></div>
          <span class="bb-line-chart-legend-text">{{
            $t("statistics.charts.currentIncomeLabel")
          }}</span>
        </div>
        <div class="d-flex align-items-center">
          <div class="bb-badge badge-xs badge-rounded danger"></div>
          <span class="bb-line-chart-legend-text">{{
            $t("statistics.charts.pastYearLabel")
          }}</span>
        </div>
      </div>
      <SalesChart
        class="sales-chart"
        ref="salesChart"
        :chartData="salesChartData"
        style="pointer-events: none;"
      ></SalesChart>
    </div>
    <div class="bb-card statistic-card total-appointments-chart-card mt-5">
      <h4>{{ $t("statistics.totalAppointmentsLabel") }}</h4>
      <div class="bb-line-chart-legend">
        <div class="d-flex align-items-center">
          <div
            class="bb-badge badge-xs badge-rounded"
            style="background: #5860AB;"
          ></div>
          <span class="bb-line-chart-legend-text">{{
            $t("statistics.charts.currentIncomeLabel")
          }}</span>
        </div>
        <div class="d-flex align-items-center">
          <div class="bb-badge badge-xs badge-rounded danger"></div>
          <span class="bb-line-chart-legend-text">{{
            $t("statistics.charts.pastYearLabel")
          }}</span>
        </div>
      </div>
      <AppointmentsChart
        class="appointments-chart"
        ref="appointmentsChart"
        :chartData="appointmentsChartData"
        style="pointer-events: none;"
      ></AppointmentsChart>
    </div>
    <StatisticsMobilePlacesFilter @placeChanged="placeChanged($event)" />
    <StatisticsMobilePeriodFilter
      ref="mobilePeriodFilter"
      @periodChanged="periodChanged($event)"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PeriodFilter from "@/view/pages/components/Statistics/PeriodFilter.vue";
import PlaceFilter from "@/view/pages/components/Statistics/PlaceFilter.vue";
import moment from "moment";
import { GET_STATISTIC } from "@/core/services/store/statistic.module.js";
import { mapGetters } from "vuex";
import StaffChart from "@/view/pages/components/Statistics/StaffChart.vue";
import SalesChart from "@/view/pages/components/Statistics/SalesChart.vue";
import AppointmentsChart from "@/view/pages/components/Statistics/AppointmentsChart.vue";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";
import StatisticsMobilePlacesFilter from "@/view/pages/components/Statistics/StatisticsMobilePlacesFilter.vue";
import StatisticsMobilePeriodFilter from "@/view/pages/components/Statistics/StatisticsMobilePeriodFilter.vue";
import { disableBodyScroll } from "body-scroll-lock";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "Statistics",
  mixins: [mobileAsideMixin],
  components: {
    PeriodFilter,
    PlaceFilter,
    StaffChart,
    SalesChart,
    AppointmentsChart,
    StatisticsMobilePlacesFilter,
    StatisticsMobilePeriodFilter
  },
  data() {
    return {
      placeId: null,
      period: {
        year: moment().format("YYYY"),
        month: moment().format("M"),
        day: moment().date()
      },
      gradient: "transparent"
    };
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("statistics.title") }
    ]);
    // Get statistic from api
    //this.getStatistic();
    // Set gradient for charts
    this.gradient = this.$refs.salesChart.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 350);
    this.gradient.addColorStop(0, "rgba(171, 172, 213, 0.75)");
    this.gradient.addColorStop(0.5, "rgba(212, 212, 234, 0.5)");
    this.gradient.addColorStop(1, "rgba(254, 254, 255, 0.25)");
    // Set current locale to moment
    moment.locale(i18nService.getActiveLanguage());
  },
  methods: {
    /**
     * Dispatch store GET_STATISTIC action to get statistic from api
     * @return {void}
     */
    getStatistic() {
      this.$store.dispatch(GET_STATISTIC, {
        placeId: this.placeId,
        year: this.period.year,
        month: this.period.month,
        day: this.period.day
      });
    },

    /**
     * Update stats based on place filter
     * @param {Object} place
     * @return {void}
     */
    placeChanged(place) {
      if (place) {
        this.placeId = place.id;
        return;
      }
      this.placeId = null;
    },

    /**
     * Update stats based on period filter
     * @param {Object} period
     * @return {void}
     */
    periodChanged({ year, month, day }) {
      this.period = {
        year: year,
        month: month
          ? moment()
              .month(month)
              .format("M")
          : null,
        day: day
      };
    },

    /**
     * Open mobile places filter element by imitating click on statistics_mobile_places_filter_toggle element
     * @return {void}
     */
    openMobilePlacesFilter() {
      document.querySelector("#statistics_mobile_places_filter_toggle").click();
      let targetElement = document.querySelector(
        "#statistics_mobile_places_filter"
      );
      disableBodyScroll(targetElement);
    },

    /**
     * Open mobile period filter element by imitating click on statistics_mobile_period_filter_toggle element
     * @return {void}
     */
    openMobilePeriodFilter() {
      document.querySelector("#statistics_mobile_period_filter_toggle").click();
      let targetElement = document.querySelector(
        "#statistics_mobile_period_filter"
      );
      disableBodyScroll(targetElement);
    }
  },
  computed: {
    ...mapGetters(["currentStatistic"]),

    /**
     * Return month name from period object
     * @return {String}
     */
    monthName() {
      if (this.period && this.period.month) {
        return moment(this.period.month, "M").format("MMM");
      }

      return "";
    },

    /**
     * Return chartData object for staffChart component
     * @return {Object}
     */
    staffChartData() {
      let staffChartData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            borderWidth: 0
          }
        ]
      };
      if (this.currentStatistic && this.currentStatistic.staffSales) {
        this.currentStatistic.staffSales.forEach(staffMember => {
          staffChartData.labels.push(staffMember.fullName);
          staffChartData.datasets[0].backgroundColor.push(staffMember.color);
          staffChartData.datasets[0].data.push(staffMember.precentage);
        });
      }

      return staffChartData;
    },

    /**
     * Return chartData object for salesChart component
     * @return {Object}
     */
    salesChartData() {
      let currentIncomeData = [];
      let pastYearData = [];
      if (this.currentStatistic && this.currentStatistic.totalSalesData) {
        currentIncomeData = Object.values(
          this.currentStatistic.totalSalesData.current_income
        );
        pastYearData = Object.values(
          this.currentStatistic.totalSalesData.past_year
        );
      }
      let salesChartData = {
        labels: moment.monthsShort(),
        datasets: [
          {
            label: this.$t("statistics.charts.currentIncomeLabel"),
            borderColor: "#5860AB",
            pointBackgroundColor: "white",
            borderWidth: 3,
            pointBorderColor: "white",
            backgroundColor: this.gradient,
            data: currentIncomeData
          },
          {
            label: this.$t("statistics.charts.pastYearLabel"),
            borderColor: "#F64E60",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 3,
            backgroundColor: "transparent",
            data: pastYearData
          }
        ]
      };

      return salesChartData;
    },

    /**
     * Return chartData object for appointmentsChart component
     * @return {Object}
     */
    appointmentsChartData() {
      let currentIncomeData = [];
      let pastYearData = [];
      if (
        this.currentStatistic &&
        this.currentStatistic.totalAppointmentsData
      ) {
        currentIncomeData = Object.values(
          this.currentStatistic.totalAppointmentsData.current_income
        );
        pastYearData = Object.values(
          this.currentStatistic.totalAppointmentsData.past_year
        );
      }
      let appointmentsChartData = {
        labels: moment.monthsShort(),
        datasets: [
          {
            label: this.$t("statistics.charts.currentIncomeLabel"),
            borderColor: "#5860AB",
            pointBackgroundColor: "white",
            borderWidth: 3,
            pointBorderColor: "white",
            backgroundColor: this.gradient,
            data: currentIncomeData
          },
          {
            label: this.$t("statistics.charts.pastYearLabel"),
            borderColor: "#F64E60",
            pointBackgroundColor: "white",
            pointBorderColor: "white",
            borderWidth: 3,
            backgroundColor: "transparent",
            data: pastYearData
          }
        ]
      };

      return appointmentsChartData;
    }
  },
  watch: {
    /**
     * Watch for placeId change
     */
    placeId: {
      immediate: true,
      deep: true,
      handler() {
        this.getStatistic();
      }
    },

    /**
     * Watch for period object change
     */
    period: {
      immediate: false,
      deep: true,
      handler() {
        this.getStatistic();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/pages/statistics.scss";
</style>
