<template>
  <div
    class="bb-period-filter statistic-period-filter"
    v-click-outside="closeFilter"
  >
    <div class="bb-period-filter-buttons" v-show="!alwaysOpen">
      <button class="bb-period-filter-prev-btn" @click="decrementPeriod">
        <i class="la la-angle-left"></i>
      </button>
      <div class="bb-period-filter-title" @click="toggleFilter">
        <span>{{ filterTitle }}</span>
      </div>
      <button class="bb-period-filter-next-btn" @click="incrementPeriod">
        <i class="la la-angle-right"></i>
      </button>
    </div>
    <div class="bb-card" v-show="isFilterOpen">
      <v-tabs class="bb-tabs" background-color="white" left v-model="activeTab">
        <v-tab
          href="#day_tab"
          :ripple="false"
          @click="updateActiveTab('day_tab')"
        >
          {{ $t("statisticsFilters.periodFilter.dayLabel") }}
        </v-tab>
        <v-tab
          href="#month_tab"
          :ripple="false"
          @click="updateActiveTab('month_tab')"
          >{{ $t("statisticsFilters.periodFilter.monthLabel") }}</v-tab
        >
        <v-tab
          href="#year_tab"
          :ripple="false"
          @click="updateActiveTab('year_tab')"
          >{{ $t("statisticsFilters.periodFilter.yearLabel") }}</v-tab
        >
        <v-tab-item id="day_tab">
          <b-calendar
            ref="smallCalendar"
            :hide-header="true"
            today-variant="success"
            :locale="locale"
            :date-info-fn="dateClass"
            @context="onContext"
            :start-weekday="1"
            v-model="dayTabData.selectedDate"
          >
            <template v-slot:nav-prev-month
              ><i class="la la-arrow-left b-calendar-custom-nav"></i
            ></template>
            <template v-slot:nav-next-month
              ><i class="la la-arrow-right b-calendar-custom-nav"></i
            ></template>
            <template v-slot:nav-this-month :disabled="disabled">
              <span class="b-calendar-month-string" style="margin-right:5px;">{{
                dayTabData.monthString
              }}</span>
              <span class="b-calendar-year-string">{{
                dayTabData.yearString
              }}</span>
            </template>
          </b-calendar>
        </v-tab-item>
        <v-tab-item id="month_tab">
          <div class="month-presentation-navigation">
            <button
              @click="
                updateMonthTabData({
                  year: parseInt(monthTabData.yearString) - 1
                })
              "
            >
              <i class="la la-arrow-left b-calendar-custom-nav"></i>
            </button>
            <span class="b-calendar-month-string">{{
              monthTabData.yearString
            }}</span>
            <button
              @click="
                updateMonthTabData({
                  year: parseInt(monthTabData.yearString) + 1
                })
              "
            >
              <i class="la la-arrow-right b-calendar-custom-nav"></i>
            </button>
          </div>
          <div class="row month-presentation-container">
            <div
              class="month-presentation col-4"
              v-for="(month, index) in months"
              :key="index"
              :class="month === monthTabData.monthString ? 'active' : ''"
            >
              <span @click="updateMonthTabData({ month: month })">{{
                month
              }}</span>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item id="year_tab">
          <div class="row month-presentation-container">
            <div
              class="month-presentation col-4"
              v-for="(year, index) in years"
              :key="index"
              :class="year === yearTabData.yearString ? 'active' : ''"
            >
              <span @click="updateYearTabData(year)">{{ year }}</span>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "PeriodFilter",
  props: {
    /**
     * Determine if filter is always open
     * @type {Boolean}
     */
    alwaysOpen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isFilterOpen: false,
      activeTab: "day_tab",
      locale: i18nService.getActiveLanguage(),
      dayTabData: {
        selectedDate: new Date(),
        monthString: moment().format("MMMM"),
        yearString: moment().format("YYYY")
      },
      monthTabData: {
        selectedDate: new Date(),
        monthString: moment().format("MMM"),
        yearString: moment().format("YYYY")
      },
      yearTabData: {
        selectedDate: new Date(),
        yearString: moment().format("YYYY")
      }
    };
  },
  mounted() {
    this.isFilterOpen = this.alwaysOpen;
    // Set current locale to moment
    moment.locale(this.locale);
  },
  methods: {
    /**
     * Toggle period filter
     * @return {void}
     */
    toggleFilter() {
      this.isFilterOpen = !this.isFilterOpen;
    },

    /**
     * Close period filter, set isFilterOpen data property to false
     * @return {void}
     */
    closeFilter() {
      if (!this.alwaysOpen) {
        this.isFilterOpen = false;
      }
    },

    /**
     * Update Active tab
     * @param {String} tab
     * @return {void}
     */
    updateActiveTab(tab) {
      this.activeTab = tab;
    },

    /**
     * Return class for every small calendar cell based on selectedDate data property
     * @param {String} ymd
     * @param {Date} date
     * @return {String}
     */
    dateClass(ymd, date) {
      return date.getTime() === this.dayTabData.selectedDate
        ? "current-view single"
        : "";
    },

    /**
     * Update monthString and yearString data properties on small calendar context
     * @param {Object} ctx
     * @return {void}
     */
    onContext(ctx) {
      let activeDate = ctx.activeYMD;
      this.dayTabData.monthString = moment(activeDate).format("MMMM");
      this.dayTabData.yearString = moment(activeDate).format("YYYY");
      this.emitPeriodChanged();
    },

    /**
     * Update monthTabData
     * @param {Object}
     * @return {void}
     */
    updateMonthTabData({ month, year }) {
      if (month) {
        this.monthTabData.selectedDate = moment(
          this.monthTabData.selectedDate
        ).month(month);
        this.monthTabData.monthString = moment(
          this.monthTabData.selectedDate
        ).format("MMM");
      }
      if (year) {
        this.monthTabData.selectedDate = moment(
          this.monthTabData.selectedDate
        ).year(year);
        this.monthTabData.yearString = moment(
          this.monthTabData.selectedDate
        ).format("YYYY");
      }
    },

    /**
     * Update yearTabData
     * @param {String} year
     * @return {void}
     */
    updateYearTabData(year) {
      this.yearTabData.selectedDate = moment(
        this.yearTabData.selectedDate
      ).year(year);
      this.yearTabData.yearString = moment(
        this.yearTabData.selectedDate
      ).format("YYYY");
    },

    /**
     * Increment day or month or year based on active tab
     * @return {void}
     */
    incrementPeriod() {
      switch (this.activeTab) {
        case "day_tab":
          this.dayTabData.selectedDate = moment(this.dayTabData.selectedDate)
            .add(1, "day")
            .toDate();
          break;
        case "month_tab":
          this.updateMonthTabData({
            month: moment(this.monthTabData.selectedDate)
              .add(1, "months")
              .format("MMM"),
            year: moment(this.monthTabData.selectedDate)
              .add(1, "months")
              .format("YYYY")
          });
          break;
        case "year_tab":
          this.updateYearTabData(
            moment(this.yearTabData.selectedDate)
              .add(1, "years")
              .format("YYYY")
          );
          break;
        default:
          break;
      }
    },

    /**
     * Decrement day or month or year based on active tab
     * @return {void}
     */
    decrementPeriod() {
      switch (this.activeTab) {
        case "day_tab":
          this.dayTabData.selectedDate = moment(this.dayTabData.selectedDate)
            .subtract(1, "day")
            .toDate();
          break;
        case "month_tab":
          this.updateMonthTabData({
            month: moment(this.monthTabData.selectedDate)
              .subtract(1, "months")
              .format("MMM"),
            year: moment(this.monthTabData.selectedDate)
              .subtract(1, "months")
              .format("YYYY")
          });
          break;
        case "year_tab":
          this.updateYearTabData(
            moment(this.yearTabData.selectedDate)
              .subtract(1, "years")
              .format("YYYY")
          );
          break;
        default:
          break;
      }
    },

    /**
     * Emits periodChanged event
     * @emits periodChanged
     */
    emitPeriodChanged() {
      switch (this.activeTab) {
        case "day_tab":
          this.$emit("periodChanged", {
            year: moment(this.dayTabData.selectedDate).format("YYYY"),
            month: moment(this.dayTabData.selectedDate).format("MMM"),
            day: moment(this.dayTabData.selectedDate).date()
          });
          break;
        case "month_tab":
          this.$emit("periodChanged", {
            year: this.monthTabData.yearString,
            month: this.monthTabData.monthString
          });
          break;
        case "year_tab":
          this.$emit("periodChanged", {
            year: this.yearTabData.yearString
          });
          break;
        default:
          break;
      }
    }
  },
  computed: {
    /**
     * Return title for period filter based on selected date
     * @return {String}
     */
    filterTitle() {
      switch (this.activeTab) {
        case "day_tab":
          return moment(this.dayTabData.selectedDate)
            .locale(this.locale)
            .format("D MMMM, YYYY");
        case "month_tab":
          return (
            this.monthTabData.monthString + ", " + this.monthTabData.yearString
          );
        case "year_tab":
          return this.yearTabData.yearString;
        default:
          return moment(this.dayTabData.selectedDate).format("D MMMM, YYYY");
      }
    },

    /**
     * Return array of months with month short name
     * @return {Array}
     */
    months() {
      moment.locale(this.locale);
      return moment.monthsShort();
    },

    /**
     * Return array of years for year tab
     * @return {Array}
     */
    years() {
      let currentYear = moment().format("YYYY");
      let years = [];
      if (moment().quarter() < 3) {
        let previousYear = parseInt(currentYear) - 1;
        years.push(previousYear.toString());
        years.push(currentYear);
        return years;
      } else {
        let nextYear = parseInt(currentYear) + 1;
        years.push(currentYear);
        years.push(nextYear.toString());
        return years;
      }
    }
  },
  watch: {
    /**
     * Watch for monthTabData change to emit periodChanged event
     */
    monthTabData: {
      deep: true,
      handler() {
        this.emitPeriodChanged();
      }
    },

    /**
     * Watch for yearTabData change to emit periodChanged event
     */
    yearTabData: {
      deep: true,
      handler() {
        this.emitPeriodChanged();
      }
    },

    /**
     * Watch for activeTab change to emit periodChanged event
     */
    activeTab: {
      handler() {
        this.emitPeriodChanged();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/period-filter.scss";
</style>
