<template>
  <div
    id="statistics_mobile_period_filter"
    ref="statistics_mobile_period_filter"
    class="offcanvas offcanvas-right"
  >
    <button
      id="statistics_mobile_period_filter_toggle"
      style="display: none;"
    ></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <a
        href="#"
        class=""
        id="statistics_mobile_period_filter_close"
        @click="closeOffcanvas('statistics_mobile_period_filter')"
      >
        <i class="fas fa-arrow-left"></i>
      </a>
      <h4 class="font-weight-bold m-0">
        {{ $t("statisticsFilters.mobilePeriodFilter.title") }}
      </h4>
      <div></div>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <PeriodFilter
        ref="periodFilter"
        @periodChanged="periodChanged($event)"
        :alwaysOpen="true"
      />
    </perfect-scrollbar>
    <!--end::Content-->
    <div class="offcanvas-footer" v-show="navigationVisible">
      <button @click="decrementPeriod">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-arrow-left"
        >
          <line x1="19" y1="12" x2="5" y2="12"></line>
          <polyline points="12 19 5 12 12 5"></polyline>
        </svg>
      </button>
      <button @click="incrementPeriod">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-arrow-right"
        >
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <polyline points="12 5 19 12 12 19"></polyline>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import StatisticsMobilePeriodFilterLayout from "@/assets/js/layout/extended/statistics-mobile-period-filter.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import PeriodFilter from "@/view/pages/components/Statistics/PeriodFilter.vue";
import { enableBodyScroll } from "body-scroll-lock";

export default {
  name: "StatisticsMobilePeriodFilter",
  components: { PeriodFilter },
  data() {
    return {
      navigationVisible: true
    };
  },
  mounted() {
    // Init statistics mobile period filter Panel
    StatisticsMobilePeriodFilterLayout.init(
      this.$refs["statistics_mobile_period_filter"]
    );
  },
  methods: {
    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      let targetElement = document.querySelector(
        "#statistics_mobile_period_filter"
      );
      enableBodyScroll(targetElement);
    },

    /**
     * Close statistics mobile period filter element
     * @return {void}
     */
    close() {
      document.querySelector("#statistics_mobile_period_filter_close").click();
    },

    /**
     * Handle period changed
     * @param {Object} period
     * @emits periodChanged
     */
    periodChanged(period) {
      if (this.$refs.periodFilter.activeTab === "year_tab") {
        this.navigationVisible = false;
      } else {
        this.navigationVisible = true;
      }
      this.$emit("periodChanged", period);
    },

    /**
     *
     */
    decrementPeriod() {
      if (this.$refs.periodFilter.activeTab === "day_tab") {
        this.$refs.periodFilter.$refs.smallCalendar.gotoPrevMonth();
      }
      if (this.$refs.periodFilter.activeTab === "month_tab") {
        this.$refs.periodFilter.updateMonthTabData({
          year: parseInt(this.$refs.periodFilter.monthTabData.yearString) - 1
        });
      }
    },

    /**
     *
     */
    incrementPeriod() {
      if (this.$refs.periodFilter.activeTab === "day_tab") {
        this.$refs.periodFilter.$refs.smallCalendar.gotoNextMonth();
      }
      if (this.$refs.periodFilter.activeTab === "month_tab") {
        this.$refs.periodFilter.updateMonthTabData({
          year: parseInt(this.$refs.periodFilter.monthTabData.yearString) + 1
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/statistics-mobile-period-filter.scss";
</style>
