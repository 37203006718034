<script>
import { Doughnut } from "vue-chartjs";
import "chartjs-plugin-datalabels";

export default {
  name: "StaffChart",
  extends: Doughnut,
  props: {
    /**
     * Chart data object
     * @type {Object}
     */
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false
        },
        plugins: {
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              size: 14
            },
            formatter: function(value) {
              return value + "%";
            }
          }
        }
      }
    };
  },
  methods: {
    /**
     * Update chart
     * @return {void}
     */
    update() {
      this.$data._chart.update();
    }
  },
  mounted() {
    // Render chart
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    /**
     * Watch for chartData change
     */
    chartData() {
      this.$data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>

<style></style>
