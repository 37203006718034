<template>
  <div
    id="statistics_mobile_places_filter"
    ref="statistics_mobile_places_filter"
    class="offcanvas offcanvas-right"
    v-click-outside="handleOutsideClick"
  >
    <button
      id="statistics_mobile_places_filter_toggle"
      style="display: none;"
    ></button>
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
    >
      <h4 class="font-weight-bold m-0">
        {{ $t("statisticsFilters.mobilePlaceFilter.title") }}
      </h4>
      <a
        href="#"
        class=""
        id="statistics_mobile_places_filter_close"
        @click="closeOffcanvas('statistics_mobile_places_filter')"
      >
        <i class="ki ki-close icon-md"></i>
      </a>
    </div>
    <!--end::Header-->
    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content mr-n5 scroll"
      style="max-height: 85vh; position: relative;"
    >
      <BBDropdown positionClass="center">
        <template v-slot:toggle>
          <span>{{
            selectedPlace
              ? selectedPlace.name
              : $t("statisticsFilters.mobilePlaceFilter.allLocationsLabel")
          }}</span>
          <i class="la la-angle-down"></i>
        </template>
        <template v-slot:items>
          <div
            class="action-item"
            :class="!selectedPlace ? 'selected' : ''"
            @click="selectPlace()"
          >
            <i :class="!selectedPlace ? 'fas fa-check' : ''"></i>
            <span>{{
              $t("statisticsFilters.mobilePlaceFilter.allLocationsLabel")
            }}</span>
          </div>
          <div
            class="action-item"
            v-for="place in allPlaces"
            :key="place.id"
            :class="
              selectedPlace && selectedPlace.id === place.id ? 'selected' : ''
            "
            @click="selectPlace(place)"
          >
            <i
              :class="
                selectedPlace && selectedPlace.id === place.id
                  ? 'fas fa-check'
                  : ''
              "
            ></i>
            <span>{{ place.name }}</span>
          </div>
        </template>
      </BBDropdown>
    </perfect-scrollbar>
  </div>
</template>

<script>
import StatisticsMobilePlacesFilterLayout from "@/assets/js/layout/extended/statistics-mobile-places-filter.js";
import { CLOSE_ELEMENT } from "@/core/services/store/offcanvas.module.js";
import BBDropdown from "@/view/content/BBDropdown.vue";
import { mapGetters } from "vuex";
import { GET_PLACES } from "@/core/services/store/places.module.js";
import { enableBodyScroll } from "body-scroll-lock";

export default {
  name: "StatisticsMobilePlacesFilter",
  components: { BBDropdown },
  data() {
    return {
      selectedPlace: null
    };
  },
  mounted() {
    // Init Date Input Panel
    StatisticsMobilePlacesFilterLayout.init(
      this.$refs["statistics_mobile_places_filter"]
    );
    // Dispatch store GET_PLACES action to get places from api
    this.$store.dispatch(GET_PLACES);
  },
  methods: {
    /**
     * Handle outside click
     * @return {void}
     */
    handleOutsideClick(event) {
      if (event.target.classList.contains("offcanvas-overlay")) {
        this.close();
      }
    },

    /**
     * Dispach CLOSE_ELEMENT action from offcanvas module
     * @return {void}
     */
    closeOffcanvas(elementId) {
      this.$store.dispatch(CLOSE_ELEMENT, elementId);
      let targetElement = document.querySelector(
        "#statistics_mobile_places_filter"
      );
      enableBodyScroll(targetElement);
    },

    /**
     * Close statistics mobile places filter element
     * @return {void}
     */
    close() {
      document.querySelector("#statistics_mobile_places_filter_close").click();
    },

    /**
     * Set selectedPlace data property
     * @param {Object|null} place
     * @return {void}
     * @emits placeChanged
     */
    selectPlace(place = null) {
      this.selectedPlace = place;
      this.$emit("placeChanged", this.selectedPlace);
    }
  },
  computed: {
    ...mapGetters(["allPlaces"])
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/statistics-mobile-places-filter.scss";
</style>
