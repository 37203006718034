<template>
  <div class="statistics-place-filter">
    <BBDropdown positionClass="right">
      <template v-slot:toggle>
        <span>{{
          selectedPlace
            ? selectedPlace.name
            : $t("statisticsFilters.placeFilter.allLocationsLabel")
        }}</span>
        <i class="la la-angle-down"></i>
      </template>
      <template v-slot:items>
        <div class="action-item" @click="selectPlace()">
          <i :class="!selectedPlace ? 'fas fa-check' : ''"></i>
          <span>{{
            $t("statisticsFilters.placeFilter.allLocationsLabel")
          }}</span>
        </div>
        <div
          class="action-item"
          v-for="place in allPlaces"
          :key="place.id"
          @click="selectPlace(place)"
        >
          <i
            :class="
              selectedPlace && selectedPlace.id === place.id
                ? 'fas fa-check'
                : ''
            "
          ></i>
          <span>{{ place.name }}</span>
        </div>
      </template>
    </BBDropdown>
  </div>
</template>

<script>
import BBDropdown from "@/view/content/BBDropdown.vue";
import { mapGetters } from "vuex";
import { GET_PLACES } from "@/core/services/store/places.module.js";

export default {
  name: "PlaceFilter",
  components: { BBDropdown },
  data() {
    return {
      selectedPlace: null
    };
  },
  mounted() {
    // Dispatch store GET_PLACES action to get places from api
    this.$store.dispatch(GET_PLACES);
  },
  methods: {
    /**
     * Set selectedPlace data property
     * @param {Object|null} place
     * @return {void}
     * @emits placeChanged
     */
    selectPlace(place = null) {
      this.selectedPlace = place;
      this.$emit("placeChanged", this.selectedPlace);
    }
  },
  computed: {
    ...mapGetters(["allPlaces"])
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/components/statistics-place-filter.scss";
</style>
