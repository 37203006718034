<script>
import { Line } from "vue-chartjs";

export default {
  name: "SalesChart",
  extends: Line,
  props: {
    /**
     * Chart data object
     * @type {Object}
     */
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: "#7C98B6",
                padding: 20,
                fontSize: 15,
                fontStyle: "500"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [4, 4],
                color: "#CBD6E2",
                drawTicks: false,
                drawBorder: false,
                zeroLineColor: "transparent"
              },
              ticks: {
                fontColor: "#7C98B6",
                padding: 20,
                fontSize: 14
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 0
          }
        }
      }
    };
  },
  methods: {
    /**
     * Update chart
     * @return {void}
     */
    update() {
      this.$data._chart.update();
    }
  },
  mounted() {
    // Render chart
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    /**
     * Watch for chartData change
     */
    chartData() {
      this.$data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>

<style></style>
